<template>
  <tab-layout class="public-order">
    <template #accountMenu>
      <div class="account-menu">
        <strong>
          Have an Account?
          <router-link :to="{ name: 'platform-login' }" class="account-menu__link">
            Sign In
          </router-link>
        </strong>
      </div>
    </template>
    <main class="public-order__main public-order__container">
      <router-view
        :orderData="orderData"
        :loading="loading"
        :token="token"
        :orderId="orderId"
        :type="type"
        @refresh="fetchOrder"
        @editedProduct="fetchOrder"
        @editDeliveryOn="openDeliveryOnModal"
        @editInvoiceDate="openInvoiceDateModal"
      />
    </main>
    <footer
      class="footer"
      v-if="
        hasFooter &&
        !loading &&
        (orderData?.actions?.length || (!orderData?.actions?.length && !isSimpleOrder))
      "
    >
      <div class="public-order__container footer__container">
        <action-switch
          :key="componentKey"
          :order="orderData"
          :token="token"
          :type="type"
          :editing-mode="editingMode"
          :save-changes-disabled="saveChangesDisabled"
          @abandon="openAbandonModal"
          @openDispute="openDisputeModal"
          @openResolve="openResolveModal"
          @confirmDelivery="openConfirmDeliveryModal"
          @scheduleOrder="openScheduleModal"
          @declineOrder="openDeclineModal"
          @acceptOrder="openAcceptModal"
          @receive="onReceiveClick"
          @saveEdit="saveEdit"
          @discardEdit="discardEdit"
          @edit="edit"
        />

        <div class="footer__price">
          <span class="footer-label" v-if="!isSimpleOrder">Total Amount</span>
          <v-subtotal-info
            v-if="!isSimpleOrder"
            :item-list="itemList"
            :delivery-fee="deliveryFee"
            :tax="tax"
            :currency="currency"
            :is-tbd="isSomeTbd"
            :discount="orderDiscountAmount"
          />
        </div>
      </div>
    </footer>
    <edit-delivery-on
      ref="editDeliveryOn"
      :order-data="orderData"
      :type="type"
      :token="token"
      @savedDeliveryOn="onSavedDeliveryOn"
    />
    <ez-confirmation-modal ref="invoiceNumberEditModal">
      <template #title>Edit Invoice Number</template>
      <template #content>
        <div class="mt-16">
          <ez-input
            formKey="order-reference-number"
            name="invoiceNumber"
            label="Invoice Number"
            placeholder="Enter Invoice Number"
            :value="invoiceNumber ? invoiceNumber : orderData.invoiceNumber"
            class="mt-16"
            @onChange="val => (invoiceNumber = val)"
          />
        </div>
      </template>
      <template #footer>
        <ez-button type="link" @click="closeInvoiceNumberModal"> Cancel </ez-button>
        <ez-button @click="saveInvoiceNumber"> Save Changes </ez-button>
      </template>
    </ez-confirmation-modal>
  </tab-layout>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import TabLayout from '@/components/layout/Tab.vue';
import flash from '@/components/ui/FlashMessage';
import ActionSwitch from '@/views/public/actions/ActionSwitch.vue';
import bus from '@/views/public/bus';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import { clone, isDeclined, isExcluded } from '@/util/utils';
import { addTotalPriceCustom } from '@/util/utilsFinCalculator';
import EditDeliveryOn from '@/views/public/actions/EditDeliveryOn';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

export default {
  components: {
    VSubtotalInfo,
    TabLayout,
    ActionSwitch,
    EditDeliveryOn,
    EzConfirmationModal,
    EzInput,
    EzButton,
  },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      componentKey: 0,
      originalOrder: {},
      loading: true,
      token: '',
      orderId: -1,
      hasFooter: true,
      editingMode: false,
      saveChangesDisabled: false,
      invoiceNumber: null,
    };
  },
  computed: {
    ...mapState('entities/orders', ['singleOrder']),
    orderData() {
      return this.singleOrder;
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      const { orderedProducts: products } = this.orderData || {};
      return products
        ?.filter(prod => !isExcluded(prod) && !isDeclined(prod))
        .map(item =>
          addTotalPriceCustom({
            item,
            multiplicand: 'priceQuantity',
            checkPriceUnits: false,
          }),
        );
    },
    tax() {
      return this.orderData?.tax || 0;
    },
    isSimpleOrder() {
      return (
        (this.orderData?.venue?.orderingType === 'simple' &&
          this.orderData?.distributor?.accountType !== 'premium') ||
        false
      );
    },
    deliveryFee() {
      return this.orderData?.deliveryFee || 0;
    },
    currency() {
      return this.orderData?.currency || {};
    },
    isSomeTbd() {
      return (this.orderData.orderedProducts || [])
        .filter(pr => !isDeclined(pr))
        .some(pr => pr.marketPrice || (!pr.priceQuantity && pr.price > 0));
    },
    orderDiscountAmount() {
      return this.orderData?.orderDiscountAmount;
    },
    // Props for VSubtotalInfo - End
  },
  methods: {
    ...mapActions('entities/orders', ['fetchPublicOrder']),
    ...mapMutations('entities/orders', [
      'SET_SELECTED_SINGLE_ORDER',
      'UPDATE_SELECTED_SINGLE_ORDER',
    ]),
    openAbandonModal() {
      bus.$emit('abandon');
    },
    onReceiveClick() {
      bus.$emit('receive');
    },
    openAcceptModal() {
      bus.$emit('acceptOrder');
    },
    openDeclineModal() {
      bus.$emit('declineOrder');
    },
    openScheduleModal() {
      bus.$emit('scheduleOrder');
    },
    openConfirmDeliveryModal() {
      bus.$emit('confirmDelivery');
    },
    openDisputeModal() {
      bus.$emit('openDispute');
    },
    openResolveModal() {
      bus.$emit('openResolve');
    },
    openDeliveryOnModal() {
      this.$refs.editDeliveryOn.open();
    },
    openInvoiceDateModal() {
      this.$refs.invoiceNumberEditModal.open();
    },
    closeInvoiceNumberModal() {
      this.$refs.invoiceNumberEditModal.close();
      this.invoiceNumber = null;
    },
    saveInvoiceNumber() {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.orderData,
        invoiceNumber: this.invoiceNumber,
        isInvoiceNumberEdited: true,
      });
      this.$refs.invoiceNumberEditModal.close();
    },
    onSavedDeliveryOn(data) {
      this.UPDATE_SELECTED_SINGLE_ORDER({ ...data });
    },
    edit() {
      this.editingMode = true;
      bus.$emit('editMode', true);
    },
    saveEdit() {
      this.originalOrder = clone(this.orderData);
      bus.$emit('saveEdit');
    },
    discardEdit() {
      this.editingMode = false;
      this.SET_SELECTED_SINGLE_ORDER(this.originalOrder);
      bus.$emit('discardEdit', false);
    },
    async fetchOrder() {
      const config = {
        headers: {
          'X-Public-Token': this.token,
        },
      };

      try {
        const {
          data: { data },
        } = await this.fetchPublicOrder({
          id: this.orderId,
          type: this.type,
          config,
        });
        this.originalOrder = data;
        this.SET_SELECTED_SINGLE_ORDER(data);
        this.loading = false;
        this.componentKey += 1;
      } catch (e) {
        flash.error({ message: 'Something went wrong' });
      }
    },
    initIntercom() {
      window.Intercom('boot', {
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
      });
    },
    setEditMode(val) {
      this.editingMode = val;
    },
    setSaveChangesDisabled(disabled) {
      this.saveChangesDisabled = disabled;
    },
  },
  mounted() {
    localStorage.clear();
    bus.$on('hideFooter', () => {
      this.hasFooter = false;
    });
    bus.$on('showFooter', () => {
      this.hasFooter = true;
    });
    bus.$on('invoiceUploaded', this.fetchOrder);
    bus.$on('editMode', this.setEditMode);
    bus.$on('productsUpdated', this.setSaveChangesDisabled);

    const { id } = this.$route.params;
    const { token } = this.$route.query;

    if (token) {
      this.orderId = Number(id);
      this.token = token;
      this.fetchOrder();
      this.initIntercom();
    } else {
      this.$router.push({
        name: 'platform-login',
        params: {
          flash: {
            title: 'Token not found',
            message: 'Please provide a token to access the public page.',
          },
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
$footer-height: 56px;

.account-menu {
  @include font-size(14px);

  &__link {
    color: $color-primary-blue;
    text-decoration: none;
  }
}
:deep() .layout-tab {
  &__navigation {
    .layout-tab__container {
      img {
        border-right: 0 !important;
      }
    }
  }
}
a.button {
  margin-left: 1em;
  @extend %base-button;

  @include button-type(
    'primary',
    $button-primary-bg,
    $button-primary-color,
    $button-primary-hover-bg,
    $button-primary-bg
  );

  text-decoration: none;
}
.public-order__container {
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
}

.public-order__main {
  margin-bottom: 14px + $footer-height;
}

.footer {
  @include fixed(bottom 0 left 0 right 0);
  border-top: 1px solid $color-gray-E9;
  background-color: #fff;

  &__container {
    @extend %flex-center;
    justify-content: space-between;
    height: $footer-height;
  }
  &__price {
    @include font-size(20px);
    font-weight: bold;
    span.footer-label {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .public-order {
    min-width: auto;
  }
  .footer {
    &__price {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      line-height: 24px;
      span.footer-label {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }
}
</style>
