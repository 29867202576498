<script>
/**
 *
 * @version 1.0.0
 * @since 3.2.1
 */
export default {
  name: 'InvalidLink',
};
</script>

<template>
  <div class="ez-not-found">
    <img src="@/assets/icons/ic_no-search.svg" alt="Error - 404">
    <p class="ez-not-found__title">Oops, it looks like your onboarding link has expired.</p>
    <p class="ez-not-found__error-code">You can ask your supplier to send you another one.</p>
    <router-link to="/" class="button button--primary">Go to Log In</router-link>
  </div>
</template>

<style lang="scss" scoped>
.ez-not-found {
  @include font-size(16px);

  margin: 8em auto;
  max-width: 768px;

  &__title {
    @include font-size(32px);
    font-weight: 600;
  }
  &__error-code {
    font-weight: 500;
    color: #8790A3;
  }
  a.button {
    @extend %base-button;

    @include button-type('primary',
      $button-primary-bg,
      $button-primary-color,
      $button-primary-hover-bg,
      $button-primary-bg
    );

    text-decoration: none;
    margin-top: 2em;
  }
}
</style>
