<script>
import { EzConfirmationModal } from '@/components/ui/Modal';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import EzButton from '@/components/ui/Button/EzButton';
import dayjs from 'dayjs';
import {
  DATE_INPUT_FORMAT,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
} from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { mapActions } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'EditDeliveryOn',
  components: {
    EzConfirmationModal,
    VDatePicker,
    EzTextarea,
    EzButton,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    token: {
      type: String,
      required: true,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      commonCy,
      deliveryOn: null,
      minDeliveryOn: null,
      deliveryOnNote: '',
    };
  },
  computed: {
    canEditDeliveryOn() {
      const { editConfig } = this.orderData;

      return editConfig?.deliveryDate;
    },
    isVenue() {
      return this.type === 'venue';
    },
    deliveryDays() {
      return this.orderData?.deliveryDays;
    },
  },
  methods: {
    ...mapActions('entities/orders', ['venueFetchPublicOrderDeliveryDate']),
    updateDeliveryOn(date) {
      this.deliveryOn = date;
    },
    close() {
      this.$refs.deliveryOnModal.close();
    },
    open() {
      this.deliveryOn = this.orderData.expectedAt;
      this.setMinDate();
      this.$refs.deliveryOnModal.open();
    },
    async setMinDate() {
      const isCompleted = [ORDER_STATUS_COMPLETED, ORDER_STATUS_COMPLETED_AFTER_DISPUTE].includes(
        this.orderData.status,
      );
      if (this.isVenue) {
        if (isCompleted) {
          this.minDeliveryOn = null; // enable all dates
          return;
        }

        const {
          data: {
            meta: { date },
          },
        } = await this.venueFetchPublicOrderDeliveryDate({
          orderId: this.orderData.id,
          token: this.token,
        });

        this.minDeliveryOn = dayjs(date).subtract(1, 'day');
      } else {
        this.minDeliveryOn = isCompleted ? null : dayjs().subtract(1, 'day');
      }
    },
    saveDeliveryOnDate() {
      const data = {
        ...this.orderData,
        expectedAt: dayjs(this.deliveryOn.format(DATE_INPUT_FORMAT)).valueOf(),
        isExpectedAtEdited: true,
        message: this.deliveryOnNote,
      };
      this.$emit('savedDeliveryOn', data);
      this.close();
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="deliveryOnModal">
    <template #title>Edit Delivery Date</template>
    <template #content>
      <div class="mt-16">
        <v-date-picker
          class="calendar mt-8"
          :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.INPUT__DELIVERY_ON"
          name="expectedAt"
          label="Delivery Date"
          v-model="deliveryOn"
          :min="minDeliveryOn"
          :deliveryDays="deliveryDays"
          form-key="filters"
          @dateChange="updateDeliveryOn"
        />
        <ez-textarea
          class="mt-12"
          :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.TEXTAREA__NOTE"
          name="note"
          label="Note"
          placeholder="Leave a Note"
          :form-key="'deliveryOnForm'"
          :value="deliveryOnNote"
          @onChange="val => (deliveryOnNote = val)"
        />
      </div>
    </template>
    <template #footer>
      <ez-button
        @click="close"
        type="link"
        :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.BUTTON__CANCEL"
        >Cancel</ez-button
      >
      <ez-button
        @click="saveDeliveryOnDate"
        :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.BUTTON__SAVE"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped>
.calendar {
  :deep() .date-picker {
    .ez-simple-dropdown__display-container {
      width: 100%;
      .placeholder {
        width: 100%;
        color: $color-gray-6C;
      }
    }
  }
}
</style>
