<template>
  <div class="detail-box">
    <small class="detail-box__label">{{ label }}</small>
    <div class="detail-box__content"><slot/></div>
  </div>
</template>

<script>
/**
 * Detail box.
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-box {
  position: relative;
  flex: 1 1 auto;
  padding: px-to-rem(10px) px-to-rem(16px);
  background-color: #F5F6FA;
  text-align: center;

  &__label {
    color: $color-gray-6C;
    @include font-size(12px);
    font-weight: 500;
  }

  &__content {
    margin-top: px-to-rem(4px);
    color: #2B2D3E;
    @include font-size(14px);
  }
}
</style>
