<template>
  <ez-confirmation-modal ref="resolveModal">
    <template #title>Resolve Dispute?</template>
    <template #content>
      <p>
        When you resolve the dispute, you will not be able to open it again. Make sure you have
        settled everything about this order with the {{ $t('global.venue') | lowercase }} before
        resolving it.
      </p>
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button @click="resolve" :is-loading="isLoadingResolve">Resolve</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';

/**
   * ResolveOrder
   * @version 1.0.0
   * @since 2.0.0
   */
export default {
  components: {
    EzButton,
    EzConfirmationModal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: false,
    },
  },
  computed: {
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
    ...mapGetters('loading', ['getLoading']),
    isLoadingResolve() {
      return this.getLoading(LOADING_KEY.RESOLVE_DISPUTE);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['publicVenueResolveDispute']),
    open() { this.$refs.resolveModal.open(); },
    close() { this.$refs.resolveModal.close(); },
    async resolve() {
      const config = {
        headers: {
          'X-Public-Token': this.token,
          loadingKey: LOADING_KEY.RESOLVE_DISPUTE,
        },
        data: {
          declinedProducts: this.excludedProductsIds,
        },
      };
      await this.publicVenueResolveDispute({
        id: this.order.id,
        type: 'distributor',
        config,
      });

      this.$emit('success');
      this.close();
      flash.success({
        title: 'Dispute resolved!',
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
