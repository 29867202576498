import i18n from '@/i18n';

export const ONBOARDING_STEP_1 = 0;
export const ONBOARDING_STEP_2 = 1;
export const ONBOARDING_STEP_3 = 2;
export const ONBOARDING_STEP_4 = 3;

const steps = {
  [ONBOARDING_STEP_1]: {
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/onboarding/new-outlet/Account'),
    title: 'Account',
    pageTitle: 'Create your account',
    pageInfo: `Create an account that will be the owner of this ${i18n.t('global.venue')}.<br>You will be logged in to it once you go to the next step.`,
    nextCta: 'Next Step',
  },
  [ONBOARDING_STEP_2]: {
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/onboarding/new-outlet/OutletInfo'),
    title: `${i18n.t('global.venue')} info`,
    pageTitle: `Please add ${i18n.t('global.venue')} info`,
    pageInfo: `Add your ${i18n.t('global.venue')}’s avatar, it’s name, contact info and addresses of the business that will be used throughout the platform.`,
    nextCta: 'Next Step',
  },
  [ONBOARDING_STEP_3]: {
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/onboarding/new-outlet/Contacts'),
    title: 'Contacts',
    pageTitle: 'Now add your contacts',
    pageInfo: 'Add your finance and ordering contacts.',
    nextCta: 'Next Step',
  },
  [ONBOARDING_STEP_4]: {
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/onboarding/new-outlet/Overview'),
    title: 'Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before you create your account, check the information below to make sure you’ve entered everything correctly and accept supplier’s terms and conditions.',
    nextCta: 'Accept T&C and Sign In',
  },
};

export default steps;
