<script>
import { EzFormModal } from '@/components/ui/Modal';
import EzInput from '@/components/ui/Input/EzInput';
import EzButton from '@/components/ui/Button/EzButton';
import EzForm from '@/components/ui/Form/EzForm';
import flash from '@/components/ui/FlashMessage';
import { isLocalStorageAccessSafeFlash } from '@/util/utils';
import { mapActions, mapMutations } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzFormModal,
    EzInput,
    EzButton,
    EzForm,
  },
  props: {
    distributor: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: false,
      default: '',
    },
    pass: {
      type: String,
      required: false,
      default: '',
    },
    formKey: {
      type: String,
      required: false,
      default: 'loginFormModal',
    },
  },
  data() {
    return {
      submitType: 'urlencoded',
      hiddenData: {
        client_id: 'web',
        grant_type: 'password',
      },
    };
  },
  methods: {
    handleLoginSuccess(loginResponse) {
      this.SET_LOGIN_PUBLIC_TOKEN(this.token);
      const tokenData = loginResponse.data.data;

      this.setTokens(tokenData);
      this.UPDATE_PUBLIC_ONBOARDING({
        owner: loginResponse.data.data.user,
      });

      this.initUser(tokenData.scope)
        .then(() => {
          this.close();
          this.fetchDefaultImages();
          this.$router.push({ name: 'buyer-onboarding-from-public', params: { id: this.distributor.id } });
        })
        .catch((error) => {
          this.resetTokens();

          flash.error({
            title: error.message,
          });

          throw error;
        });
    },
    initUser(scope) {
      switch (scope) {
        case 'distributor':
          return this.initDistributorLoggedUser();
        case 'venue':
          return this.initVenueLoggedUser();
        default:
          return Promise.reject(new Error(`Invalid scope ${scope}`));
      }
    },
    setTokens(tokens) {
      const {
        accessToken,
        accessTokenExpiresAt,
        refreshToken,
        refreshTokenExpiresAt,
        scope,
        user,
      } = tokens;
      const { permissions } = user;

      if (!isLocalStorageAccessSafeFlash()) { return; }

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
      localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiresAt);
      localStorage.setItem('role', scope);
      localStorage.setItem('permissions', JSON.stringify(permissions));
    },
    resetTokens() {
      this.EMPTY_CART();

      if (!isLocalStorageAccessSafeFlash()) { return; }
      localStorage.clear();
    },
    onError() {
      flash.error({
        title: 'Sign in failed!',
        message: 'There was an error with your E-Mail/Password combination. Please try again.',
      });

      this.$emit('loginError');
    },
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapActions('entities/users', [
      'initVenueLoggedUser',
      'initDistributorLoggedUser',
    ]),
    ...mapMutations('cart', [
      'EMPTY_CART',
    ]),
    ...mapMutations('entities/venues', [
      'SET_LOGIN_PUBLIC_TOKEN',
      'UPDATE_PUBLIC_ONBOARDING',
    ]),
    signIn() {
      this.$refs.signInForm.onSubmit();
    },
    open() {
      this.$refs.signInModal.open();
    },
    close() {
      this.$refs.signInModal.close();
    },
  },
};
</script>
<template>
  <ez-form-modal ref="signInModal">
    <template #title>Sign In</template>
    <template #content>
      <ez-form
        ref="signInForm"
        :showLoader="false"
        :formKey="formKey"
        action="/manager/oauth"
        method="post"
        :additionalData="hiddenData"
        :submitType="submitType"
        @success="handleLoginSuccess"
        @error="onError">
        <ez-input
          class="mt-12"
          :formKey="formKey"
          label="Email Address"
          validators="email|required"
          placeholder="Enter Your Email Address"
          :value="email || ''"
          name="username" />
        <ez-input
          class="mt-12"
          :formKey="formKey"
          validators="required"
          placeholder="Enter Your Password"
          type="password"
          :value="pass || ''"
          name="password"
          label="Password" />
      </ez-form>
    </template>
    <template #footer>
      <div class="modal-footer">
        <router-link
          :to="{ name: 'platform-forgot-password'}">Forgot Password?
        </router-link>
        <router-link :to="{ name: 'terms-and-conditions'}" target="_blank">Terms and Conditions</router-link>
        <div>
          <EzButton type="link" @click="close">Cancel</EzButton>
          <EzButton formType="button" @click="signIn">Sign In</EzButton>
        </div>
      </div>
    </template>
  </ez-form-modal>
</template>
<style lang="scss" scoped>
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: $color-primary-blue;
      text-decoration: none;
      @include font-size(14px);
    }
  }
</style>
