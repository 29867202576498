<template>
  <ez-confirmation-modal ref="orderDecline" type="red" icon="question">
    <template #title>Decline Entire Order?</template>
    <template #content>
      <p>Order will be declined completely.</p>
      <p>
        If you wish, you can also provide a reason why it is being declined and
        {{ $t('global.venue') | lowercase }} will be notified.
      </p>

      <hr />

      <ez-notes v-if="hasNotes" :order="order" />

      <ez-textarea
        formKey=""
        name="note"
        label="Note"
        placeholder="Explain why you are declining this order. (Optional)"
        @onChange="val => (declineMessage = val)"
      />
    </template>
    <template #footer>
      <ez-button type="link" @click="close">Cancel</ez-button>
      <ez-button type="red" @click="declineOrder">Decline Entire Order</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import { EzNotes } from '@/views/common/orders/notes';
import flash from '@/components/ui/FlashMessage';
import EzTextarea from '@/components/ui/Textarea';
/**
 * Decline Order
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzTextarea,
    EzConfirmationModal,
    EzButton,
    EzNotes,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      declineMessage: '',
    };
  },
  computed: {
    acceptText() {
      return this.excludedProductsIds.length ? 'Partially Accept Order' : 'Accept Order';
    },
    hasNotes() {
      return (this.order.notes || [])
        .filter(n => !!n.message || (n.images || []).length).length;
    },
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['publicOrderDecline']),
    open() { this.$refs.orderDecline.open(); },
    close() { this.$refs.orderDecline.close(); },
    async declineOrder() {
      const config = {
        headers: {
          'X-Public-Token': this.token,
        },
        data: {
          message: this.declineMessage,
        },
      };
      await this.publicOrderDecline({
        id: this.order.id,
        type: 'distributor',
        config,
      });
      this.$emit('success');
      this.close();
      this.declineMessage = '';

      flash.success({
        title: 'Order declined.',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .notes { margin-bottom: 12px; }
</style>
