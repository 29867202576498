<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import Paper from '@/components/layout/WizardOverviewPaper';
import EzButton from '@/components/ui/Button/EzButton';
import VEntityInfo from '@/components/v3/elements/VEntityInfo/index';
import { mapActions, mapMutations, mapState } from 'vuex';
import { ONBOARDING_STEP_1, ONBOARDING_STEP_3 } from '@/views/public/onboarding/new-outlet/steps';
import flash from '@/components/ui/FlashMessage';
import Tos from '@/views/public/onboarding/Tos';
import LoginModal from '@/views/public/onboarding/new-outlet/LoginModal';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzButton,
    VEntityInfo,
    Tos,
    LoginModal,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    distributor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buttonType: 'primary',
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'publicOnboarding',
    ]),
    config() {
      return {
        headers: {
          'X-Public-Token': this.token,
        },
      };
    },
    venue() {
      return this.publicOnboarding.venue;
    },
    shippingAddress() {
      return this.venue.shippingAddress;
    },
    billingAddress() {
      return this.venue.billingAddress;
    },
    hasOrderingUser() {
      return Object.keys(this.publicOnboarding.orderingUser).length;
    },
    isTermsAccepted() {
      return this.publicOnboarding.termsAndConditions;
    },
  },
  watch: {
    isTermsAccepted(val) {
      if (val) {
        this.buttonType = 'green';
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
  },
  methods: {
    addressFormat(data) {
      return [
        ...(data.street ? [`${data.street}`] : []),
        ...(data.city ? [`${data.zipCode} ${data.city}`] : []),
        ...(data.country ? [`${data.country}`] : []),
        ...(data.state ? [`${data.state}`] : []),
      ].join(', ');
    },
    editContacts() {
      this.$emit('stepBack', ONBOARDING_STEP_3);
    },
    editVenue() {
      this.$emit('stepBack', ONBOARDING_STEP_1);
    },
    readTermsAndConditions() {
      this.$refs.tosModal.open();
    },
    async onNextStep() {
      try {
        await this.connectPublicExistingOnboarding({
          distributorId: this.distributor.id,
          venueId: this.venue.id,
          config: this.config,
          data: {
            termsAndConditions: this.isTermsAccepted,
          },
        });
        await this.$refs.signInModalOverview.signIn();
        flash.success({
          title: this.$t('venues.onboarding.flash.title'),
          message: this.$t('venues.onboarding.flash.message', {
            distributorName: this.distributor.name,
          }),
        });
        this.CLEAR_PUBLIC_ONBOARDING();
      } catch (e) {
        await this.$router.push({ name: 'platform-login' });
        flash.error({ title: 'Something went wrong' });
      }
    },
    ...mapActions('entities/venues', [
      'connectPublicExistingOnboarding',
    ]),
    ...mapMutations('entities/venues', [
      'CLEAR_PUBLIC_ONBOARDING',
      'UPDATE_PUBLIC_ONBOARDING',
    ]),
  },
  mounted() {
    this.disableFooterActions();
    if (this.isTermsAccepted) {
      this.buttonType = 'green';
      this.enableNextStep();
    }
  },
};
</script>
<template>
  <div>
    <div class="terms-btn-container mb-24">
      <ez-button
        @click="readTermsAndConditions"
        :type="buttonType"
      >
        <template v-if="isTermsAccepted">
          <font-awesome-icon icon="check"/>
        </template>
        Read Terms & Conditions
      </ez-button>
    </div>
    <paper>
      <div class="venue">
        <VEntityInfo
          class="mb-16"
          imageSize="64px"
          :imageUrl="venue.logo"
          imageBorderRadius="50%"
          imageHasBorder />
        <p class="venue__name">{{ venue.name }}</p>
      </div>
    </paper>
    <paper>
      <template #title>Main Info</template>
      <template #actions>
        <ez-button @click="editVenue" type="link" formType="button">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="info-segment">
        <p class="info-segment__title">{{ $t('global.venue') }} Name</p>
        <p class="info-segment__info">{{ venue.name }}</p>
      </div>
      <div v-if="venue.customInfo" class="info-segment mt-16">
        <p class="info-segment__title">Additional Info</p>
        <p class="info-segment__info">{{ venue.customInfo }}</p>
      </div>
      <hr>
      <div class="info-segment mt-16">
        <p class="info-segment__title">ADDRESSES</p>
      </div>
      <div class="info-segment mt-16">
        <p class="info-segment__title">Delivery Address</p>
        <p class="info-segment__info">{{ addressFormat(shippingAddress) }}</p>
      </div>
      <div class="info-segment mt-16" v-if="billingAddress">
        <p class="info-segment__title">Billing Address</p>
        <p class="info-segment__info">{{ addressFormat(billingAddress) }}</p>
      </div>
    </paper>
    <paper>
      <template #title>Account</template>
      <div class="contact-item">
        <div class="contact-item__name">{{ publicOnboarding.owner.name }}</div>
        <div class="contact-item__type">{{ $t('global.accountRep') }}</div>
      </div>
    </paper>
    <paper>
      <template #title>Contacts</template>
      <template #actions>
        <ez-button @click="editContacts" type="link" formType="button">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="contact-item">
        <div class="contact-item__name">{{ publicOnboarding.financeUser.name }}</div>
        <div class="contact-item__type">Finance Contact</div>
      </div>
      <div class="contact-item" v-if="hasOrderingUser">
        <div class="contact-item__name">{{ publicOnboarding.orderingUser.name }}</div>
        <div class="contact-item__type">Ordering Contact</div>
      </div>
    </paper>
    <tos ref="tosModal" :distributor="distributor" :token="token"></tos>
    <LoginModal
      :token="token"
      :distributor="distributor"
      :email="publicOnboarding.owner.email"
      :pass="publicOnboarding.owner.pass"
      ref="signInModalOverview" />
  </div>
</template>
<style lang="scss" scoped>
  .venue {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__name {
      margin: 0;
      @include font-size(16px, 19px);
      font-weight: 500;
      color: $color-gray-25;
    }
  }
  .info-segment {
    &__title {
      @include font-size(12px, 14px);
      color: $color-gray-6C;
      margin: 0 0 6px 0;
      font-weight: 500;
    }
    &__info {
      @include font-size(14px, 16px);
      color: #2B2D3E;
      margin: 0;
    }
  }
  .contact-item {
    @extend %flex-center;
    justify-content: space-between;
    @include font-size(14px, 16px);
    padding: 10px 0;
    &__name {
      color: $color-gray-25;
    }
    &__type {
      color: $color-gray-6C;
    }
  }

  .accepted-tos {
    @extend %flex-center;
    &__icon {
      color: $color-primary-green;
    }
    &__text {
      color: $color-gray-6C;
      @include font-size(14px, 16px);
      margin-left: 8px;
    }
  }
  .terms-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
