<script>
import { mapActions, mapMutations } from 'vuex';
import NewOnboarding from '@/views/public/onboarding/new-outlet/NewOnboarding';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      onboardingInfo: {},
      redirect: null,
    };
  },
  computed: {
    whichFlow() {
      return NewOnboarding;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query.new': function () {
      if (this.$route.query.new) {
        this.redirect = true;
      }
    },
  },
  methods: {
    async fetchOnboardingInfo() {
      const config = {
        headers: {
          'X-Public-Token': this.token,
        },
      };
      try {
        const { data } = await this.fetchPublicExistingOnboardingInfo({ config });
        this.onboardingInfo = data.data;
        this.UPDATE_PUBLIC_ONBOARDING({
          venue: this.onboardingInfo.venue,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        const { response } = error;
        if (response.status === 404) {
          await this.$router.push({ name: 'invalid-link' });
        }
      }
    },
    ...mapActions('entities/venues', [
      'fetchPublicExistingOnboardingInfo',
    ]),
    ...mapMutations('entities/venues', [
      'UPDATE_PUBLIC_ONBOARDING',
    ]),
  },
  created() {
    window.fbq('track', 'PageView');
    window.gtag('config', 'G-0DNFWSZRST');
    this.fetchOnboardingInfo();
    if (this.$route.query.new) {
      this.redirect = true;
    }
  },
};
</script>
<template>
  <component
    v-if="onboardingInfo.distributor"
    :distributor="onboardingInfo.distributor"
    :is="whichFlow"
    :venue="onboardingInfo.venue"
    :token="token" />
</template>
<style lang="scss" scoped></style>
