<script>
import EzInput from '@/components/ui/Input/EzInput';
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapState, mapMutations } from 'vuex';
import EzForm from '@/components/ui/Form/EzForm';
import LoginModal from '@/views/public/onboarding/new-outlet/LoginModal';
import Checkbox from '@/components/ui/Checkbox/Checkbox.vue';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    EzForm,
    EzInput,
    LoginModal,
    Checkbox,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    distributor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'newAccount',
      pass: '',
      confirmPass: '',
      formValid: false,
      requiredValues: {
        name: null,
        email: null,
        password: null,
      },
      phone: '',
      termsAndConditionsChecked: false,
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'publicOnboarding',
    ]),
    venue() {
      return this.publicOnboarding.venue;
    },
    owner() {
      return this.publicOnboarding.owner;
    },
    isEdit() {
      return Object.keys(this.owner).length > 0;
    },
    action() {
      return `/public/distributors/${this.distributor.id}/venues/${this.venue.id}/users`;
    },
    additionalData() {
      return {
        type: 'owner',
      };
    },
    additionalHeader() {
      return {
        'X-Public-Token': this.token,
      };
    },
    isMatch() {
      return this.pass === this.confirmPass;
    },
    isFormValid() {
      return this.formValid && this.isMatch && this.termsAndConditionsChecked;
    },
    errorMessagePass() {
      return this.isMatch ? '' : 'Confirm field does not match';
    },
  },
  watch: {
    isFormValid(val) {
      if (val) this.enableNextStep();
      else this.disableNextStep();
    },
  },
  methods: {
    onNextStep() {
      this.$refs.userForm.onSubmit();
    },
    async onSubmitSuccess({ data }) {
      await this.UPDATE_PUBLIC_ONBOARDING({
        owner: {
          ...data.data,
          pass: this.pass,
        },
        login: false,
      });
      await this.$refs.hiddenLoginModal.signIn();
    },
    async updateFormState(name, ev) {
      if (name === 'password') this.pass = ev;
      this.requiredValues[name] = await this.$validator.validate(name, ev);
      this.validateForm();
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach((key) => {
        if (!this.requiredValues[key]) this.formValid = false;
      });
    },
    initialFormState() {
      this.requiredValues = {
        name: this.owner.name,
        email: this.owner.email,
        password: this.owner.pass,
      };
      this.pass = this.owner.pass;
      this.confirmPass = this.owner.pass;
      this.phone = this.owner.phone;
    },
    onTermsAndConditionsChange() {
      this.termsAndConditionsChecked = !this.termsAndConditionsChecked;
    },
    ...mapMutations('entities/venues', [
      'UPDATE_PUBLIC_ONBOARDING',
    ]),
    ...mapMutations('errors', ['SET_ERROR']),
  },
  mounted() {
    if (this.isEdit) {
      this.initialFormState();
      this.validateForm();
      if (this.termsAndConditionsChecked) this.enableNextStep();
    }
    this.disableFooterActions();
  },
};
</script>
<template>
  <div>
    <ez-form
      class="ordering-fields"
      ref="userForm"
      @success="onSubmitSuccess"
      :additionalHeaders="additionalHeader"
      :formKey="formKey"
      :additionalData="additionalData"
      :action="action"
      method="post">
      <ez-input
        :formKey="formKey"
        name="name"
        :disabled="isEdit"
        :value="owner.name"
        placeholder="Enter Owner Name"
        validators="required"
        @onChange="updateFormState('name', $event)"
        label="Name" />
      <ez-input
        class="mt-12"
        :formKey="formKey"
        :value="owner.email"
        :disabled="isEdit"
        label="Email Address"
        @onChange="updateFormState('email', $event)"
        validators="email|required"
        placeholder="Enter Your Email Address"
        name="email" />
      <ez-input
        class="mt-12"
        :formKey="formKey"
        :disabled="isEdit"
        :value="owner.phone"
        placeholder="Enter Phone Number"
        name="phone"
        label="Phone Number" />
      <hr>
      <ez-input
        class="mt-12"
        :formKey="formKey"
        :value="owner.pass"
        :disabled="isEdit"
        @onChange="updateFormState('password', $event)"
        validators="required"
        placeholder="Enter Your Password"
        type="password"
        name="password"
        ref="password"
        label="Password" />
    </ez-form>
    <ez-input
      class="mt-12"
      formKey="confirmPass"
      :value="owner.pass"
      :disabled="isEdit"
      :error-msg="errorMessagePass"
      placeholder="Enter Your Password Again"
      type="password"
      @onChange="(val) => confirmPass = val"
      name="confirm"
      label="Confirm Your Password" />
    <div class="terms-and-conditions mt-12">
      <checkbox
        class="terms-and-conditions__checkbox mr-8"
        name="termsAndConditions"
        :checked="termsAndConditionsChecked"
        @change="onTermsAndConditionsChange"
      />
      <span class="terms-and-conditions__label">
        I agree to the
        <router-link :to="{ name: 'terms-and-conditions' }" target="_blank">
          Terms and Conditions
        </router-link>
        and
        <router-link :to="{ name: 'privacy-policy' }" target="_blank">
          Privacy Policy
        </router-link>.
      </span>
    </div>
    <login-modal
      form-key="loginModalHidden"
      :token="token"
      :distributor="distributor"
      :email="publicOnboarding.owner.email"
      :pass="publicOnboarding.owner.pass"
      ref="hiddenLoginModal" />
  </div>
</template>
<style lang="scss" scoped>
.terms-and-conditions {
  display: flex;
  align-items: center;

  &__checkbox {
    display: inline;
  }

  &__label {
    @include font-size(14px);
    color: $text-color;
    a {
      display: inline-block;
      color: $color-primary-blue;
      text-decoration: none;
    }
  }
}
</style>
