<template>
  <ez-confirmation-modal ref="orderConfirmation" icon="question">
    <template #title>Receive Order?</template>
    <template #content>
      <p>Please review before confirming that you received this order.</p>

      <hr />

      <ez-textarea
        name="message"
        label="Note"
        formKey="note"
        :value="confirmMessage"
        @onChange="val => (confirmMessage = val)"
        placeholder="Add a Note. (Optional)"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="close"
        :data-cy="commonCy.SINGLE_ORDER.ACCEPT_ORDER_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="receiveOrder"
        :data-cy="commonCy.SINGLE_ORDER.ACCEPT_ORDER_MODAL.BUTTON__ACCEPT"
      >
        Submit
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import EzTextarea from '@/components/ui/Textarea';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

/**
 * AcceptOrder
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzTextarea,
    EzConfirmationModal,
    EzButton,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmMessage: '',
      commonCy,
    };
  },
  computed: {
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['publicOrderActions']),
    open() {
      this.$refs.orderConfirmation.open();
    },
    close() {
      this.confirmMessage = '';
      this.$refs.orderConfirmation.close();
    },
    async receiveOrder() {
      const config = {
        headers: {
          'X-Public-Token': this.token,
        },
      };
      const data = {
        message: this.confirmMessage,
        declinedProducts: this.excludedProductsIds,
      };
      await this.publicOrderActions({
        id: this.order.id,
        action: 'receive',
        type: 'venue',
        config,
        data,
      });
      this.$emit('success');
      this.close();
      this.confirmMessage = '';

      flash.success({
        title: 'Order received.',
        message: 'You have successfully received the order.',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .notes {
  margin-bottom: 12px;
}
</style>
