<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import downloadAttachment from '@/util/downloadAttachment';
import { EzModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzModal,
    EzButton,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    distributor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tosText: '',
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'publicOnboarding',
    ]),
    config() {
      return {
        headers: {
          'X-Public-Token': this.token,
        },
      };
    },
  },
  methods: {
    async fetchTos() {
      const { data } = await this.fetchPublicExistingOnboardingTos({
        distributorId: this.distributor.id,
        config: this.config,
      });
      this.tosText = data.data;
    },
    async downloadTosPdf() {
      const { data } = await this.fetchPublicExistingOnboardingTosPdf({
        distributorId: this.distributor.id,
        config: this.config,
      });
      downloadAttachment(data, `${this.distributor.name}'s terms&conditions.pdf`);
    },
    open() {
      this.$refs.modalTerms.open();
    },
    close() {
      this.$refs.modalTerms.close();
    },
    confirmTermsAndConditions() {
      this.UPDATE_PUBLIC_ONBOARDING({
        termsAndConditions: true,
      });
      this.close();
    },
    ...mapActions('entities/venues', [
      'fetchPublicExistingOnboardingTos',
      'fetchPublicExistingOnboardingTosPdf',
    ]),
    ...mapMutations('entities/venues', [
      'UPDATE_PUBLIC_ONBOARDING',
    ]),
  },
  mounted() {
    this.fetchTos();
  },
};
</script>
<template>
  <EzModal class="modalTerms" ref="modalTerms" @close="close()">
    <div class="modal__inner">
      <button class="modal__download" @click="downloadTosPdf">
        <font-awesome-icon icon="download"/>
      </button>
      <button class="modal__close" @click="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <!-- eslint-disable-next-line max-len -->
          <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
        </svg>
      </button>
      <div class="modal__title">
        <h2>Terms & Conditions</h2>
      </div>
      <div class="modal__content">
        <div class="tos" v-html="tosText"></div>
      </div>
      <div class="modal__footer">
        <slot name="footer">
          <ez-button @click="close()" type="link">Cancel</ez-button>
          <ez-button
            @click="confirmTermsAndConditions"
          >
            I Read the Terms & Conditions
          </ez-button>
        </slot>
      </div>
    </div>
  </EzModal>
</template>
<style lang="scss">
  .tos {
    word-break: break-all;
    color: #6C7995;
    h1, h2 {
      color: $color-gray-25;
    }
    h1 {
      @include font-size(24px, 32px);
    }
    h2 {
      @include font-size(20px, 28px);
      font-weight: 600;
    }

    code {
      @include font-size(16px);
      background-color: $color-gray-F5;
      color: $color-gray-6C;
      padding: 12px 16px;
    }
    blockquote {
      margin-left: 0;
      padding-left: 16px;
      border-left: 1px solid $color-gray-E9;
    }
  }
  .modalTerms {
    .modal {
      &__inner {
        position: relative;
        padding: px-to-rem(24px) px-to-rem(24px) px-to-rem(16px) px-to-rem(24px);
        max-width: 640px;
      }

      &__close {
        @extend %button-reset;
        position: absolute;
        top: 25px;
        right: px-to-rem(24px);
        @include font-size(18px);
        cursor: pointer;

        svg {
          fill: $secondary-color;
        }
      }

      &__download {
        @extend %button-reset;
        position: absolute;
        top: 25px;
        right: px-to-rem(55px);
        @include font-size(18px);
        cursor: pointer;
        color: $secondary-color;
      }

      &__title {
        margin-bottom: 1.5em;
        @extend %flex-center;

        h2 {
          margin: 0;
          @include font-size(20px);
          font-weight: 600;
        }
      }

      &__content {
        max-height: 564px;
        overflow: auto;

        .products-merge-list {
          :deep() .floating-bar {
            top: 0;
            margin-bottom: 0;
          }
          :deep() table {
            th, td {
              &:last-child {
                padding-right: 16px;
              }
            }
          }
        }

        :deep() .ez-empty-state {
          &__image {
            max-width: none;
          }
          img {
            width: 256px;
            height: 118px;
          }
        }
      }

      &__footer {
        @extend %flex-center;
        justify-content: flex-end;
        padding-top: 16px;
      }
    }
  }
</style>
