<script>
/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */

import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button/EzButton';
import EzWizard from '@/components/layout/Wizard';
import steps, {
  ONBOARDING_STEP_1, ONBOARDING_STEP_2, ONBOARDING_STEP_3, ONBOARDING_STEP_4,
} from '@/views/public/onboarding/new-outlet/steps';
import Footer from '@/views/public/onboarding/existing-outlet/Footer';
import LoginModal from '@/views/public/onboarding/new-outlet/LoginModal';
import EzAlert from '@/components/ui/Alert';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
    Footer,
    EzAlert,
    LoginModal,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    distributor: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      currentStep: 0,
      SUPPLIER_STEP_1: ONBOARDING_STEP_1,
      SUPPLIER_STEP_2: ONBOARDING_STEP_2,
      SUPPLIER_STEP_3: ONBOARDING_STEP_3,
      SUPPLIER_STEP_4: ONBOARDING_STEP_4,
      nextDisabled: true,
    };
  },
  computed: {
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === ONBOARDING_STEP_1;
    },
    isLastStep() {
      return this.currentStep === ONBOARDING_STEP_4;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    onWizardExit() {
      this.$router.push({ name: 'platform-login' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= ONBOARDING_STEP_1 && step <= ONBOARDING_STEP_4) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= ONBOARDING_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    openLogInModal() {
      this.$refs.signInModal.open();
    },
    onStepCompleted() {
      if (this.currentStep >= ONBOARDING_STEP_4) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
};
</script>
<template>
  <div>
    <ez-wizard
      @exit="onWizardExit"
      @back="goToPreviousStep">
      <template #title>Account Opening Form</template>
      <template #nav>
        <ul>
          <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_1,
          'ez-step-completed': currentStep > SUPPLIER_STEP_1,
        }">
            1. Account
          </li>
          <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_2,
          'ez-step-completed': currentStep > SUPPLIER_STEP_2,
        }">
            2. {{ $t('global.venue') }} Info
          </li>
          <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_3,
          'ez-step-completed': currentStep > SUPPLIER_STEP_3,
        }">
            3. Contacts
          </li>
        </ul>
      </template>
      <template #actions>
        <div
          v-tooltip="(nextDisabled && currentStep ===  SUPPLIER_STEP_4) ? {
            content: `Please read the Terms & Conditions in <br> order to be able to complete
            the form.`,
            classes: ['tooltip-general'],
          } : {}"
        >
          <ez-button
            :disabled="nextDisabled"
            @click="goToNextStep">{{ nextCta }}</ez-button>
        </div>
      </template>
      <template #prevStep>
        <ez-button
          v-if="currentStep > SUPPLIER_STEP_1 && currentStep < SUPPLIER_STEP_4"
          type="link"
          formType="button"
          @click="goToPreviousStep">
          <font-awesome-icon icon="arrow-left"/>
          <span>Back</span>
        </ez-button>
      </template>
      <template #pageBanner>
        <ez-alert type="purple" size="inline" class="banner" v-if="currentStep === SUPPLIER_STEP_1">
          <template #icon>
            <font-awesome-layers>
              <font-awesome-icon icon="circle" />
              <font-awesome-icon
                icon="question"
                transform="shrink-6"
                :style="{ color: '#EEDFF4' }" />
            </font-awesome-layers>
          </template>
          <p class="banner__text"><span class="banner-title">Already have an account?</span><br />
            You can sign in to your account and continue with the onboarding. By signing in as a
            group level user, this {{ $t('global.venue') | lowercase }} will automatically be added
            to your {{ $t('global.venue') | lowercase }} group</p>
          <ez-button
            @click="openLogInModal"
            class="banner__btn"
            type="blue-link">Sign In</ez-button>
        </ez-alert>
      </template>
      <template #pageTitle>{{ currentStepPageTitle }}</template>
      <template #pageInfo><span v-html="currentStepPageInfo"/></template>
      <component
        :distributor="distributor"
        :token="token"
        :is="currentStepComponent"
        @stepCompleted="onStepCompleted"
        @stepBack="onStepBack" />
      <footer
        :distributor="distributor"
        @accepted="enableNextStep"
        @download="downloadTosPdf"
        :hasActions="footerActions" />
    </ez-wizard>
    <login-modal
      :token="token"
      :distributor="distributor"
      ref="signInModal" />
  </div>
</template>
<style lang="scss" scoped>
  .banner {
    padding-bottom: 0;
    margin-top: 0;
    .banner__text {
      color: $color-gray-6C;
      font-weight: 400;
      margin: 0;
      .banner-title {
        color: $color-gray-25;
        font-weight: 500;
      }
    }
    .banner__btn {
      padding: 0;
    }
  }
</style>
