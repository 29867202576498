<template>
  <div class="public-order">
    <abandon-order
      ref="abandonOrder"
      :token="token"
      :order="orderData"
      type="venues"
      @success="$emit('refresh')"
    />

    <accept-order
      ref="receiveOrder"
      :order="orderData"
      :token="token"
      @success="$emit('refresh')"
    />
    <confirm-delivery
      ref="confirmDelivery"
      :order="orderData"
      :action="`/venue/public/orders/${orderId}/complete`"
      :token="token"
      @success="$emit('refresh')"
    />

    <open-dispute
      ref="dispute"
      :action="`/venue/public/orders/${orderId}/dispute`"
      @success="$emit('refresh')"
      :token="token"
    />

    <resolve-dispute
      ref="resolveDispute"
      :order="orderData"
      :action="`/venue/public/orders/${orderId}/dispute`"
      @success="$emit('refresh')"
      :token="token"
    />

    <div v-if="orderData" class="public-order__wrapper">
      <ez-info-modal ref="infoModal" v-if="distributor" :data="distributor">
        <template #title>{{ $t('global.distributor') }} Info</template>
      </ez-info-modal>

      <!--   Info messages // Start   -->

      <ez-alert variant="disclaimer" v-if="editingMode" size="big">
        <template #icon>
          <font-awesome-icon icon="exclamation-circle" />
        </template>
        <template #title>
          <span>Edit mode active</span>
        </template>
        <p>
          Edit the existing products to this order. Once you save the changes, the
          {{ $t('global.distributor') }} will be notified.
        </p>
      </ez-alert>

      <div class="mb-16" v-if="checkIsMpOrToBeDetermined && hasMpInOrder && !editingMode">
        <ez-alert variant="disclaimer" class="m-0" size="big">
          <template #icon>
            <font-awesome-icon icon="exclamation-circle" />
          </template>
          <p v-if="hasMpInOrder && hasTbdInOrder">
            This order contains products with market prices and fractional units which may affect
            the total amount (incl. subtotal and taxes) based on the actual delivered quantity.
          </p>
          <p v-if="hasMpInOrder && !hasTbdInOrder">
            Product with market price is included in this order. Market prices affect the total
            amount, subtotal and taxes.
          </p>
        </ez-alert>
      </div>

      <!--   Info messages // End   -->

      <header class="order-header">
        <h2>Order Request</h2>
        <status-badge :status="orderData.status || 'pending'" />
        <div class="mobile-actions">
          <ez-button class="venue-details" type="link" formType="button" @click="showInfoModal">
            <font-awesome-icon icon="info-circle" />
          </ez-button>
        </div>
      </header>
      <single-order
        :editing-mode="editingMode"
        :can-edit-delivery-on="canEditDeliveryOn"
        :can-edit-invoice-number="canEditInvoiceNumber"
        :order="orderData"
        @productClick="onProductClick"
        @editedProduct="() => $emit('editedProduct')"
        @editDeliveryOn="() => $emit('editDeliveryOn')"
        @editInvoiceNumber="() => $emit('editInvoiceDate')"
        :isPublicOrder="true"
        :isSimpleOrder="isSimpleOrder"
        :token="token"
        :type="type"
        :invoice-action="`/venue/public/orders/${orderId}/invoice`"
      >
        <template #distributor>
          <ez-entity-info class="venue-details" :imgUrl="distributor.logo" imgBorderRadius="50%">
            <span class="venue-info__name">{{ distributor.name }}</span>
            <ez-button
              class="venue-details"
              type="secondary"
              formType="button"
              @click="showInfoModal"
            >
              <font-awesome-icon icon="info-circle" />
              <span>{{ $t('global.distributor') }} Info</span>
            </ez-button>
          </ez-entity-info>
        </template>
      </single-order>
    </div>
  </div>
</template>

<script>
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzButton from '@/components/ui/Button';
import { SingleOrder } from '@/views/common/orders';
import { EzInfoModal } from '@/components/ui/Modal';
import StatusBadge from '@/views/common/status-badge';
import { PARTIALLY_ACCEPTABLE } from '@/util/constants';
import ConfirmDelivery from '@/views/common/orders/actions/ConfirmOrder.vue';
import OpenDispute from '@/views/public/actions/OpenDispute';
import ResolveDispute from '@/views/public/actions/ResolveDispute';
import AbandonOrder from '@/views/public/actions/AbandonOrder';
import { mapActions, mapState } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import EzAlert from '@/components/ui/Alert/EzAlert';
import { isPremium } from '@/util/utils';
import { isVenue } from '@/mixins/permissions/utils';
import AcceptOrder from '@/views/public/actions/ReceiveOrder';
import bus from './bus';

export default {
  components: {
    EzAlert,
    ResolveDispute,
    OpenDispute,
    EzEntityInfo,
    SingleOrder,
    EzButton,
    StatusBadge,
    EzInfoModal,
    ConfirmDelivery,
    AbandonOrder,
    AcceptOrder,
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    token: {
      type: String,
    },
    orderId: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      editingMode: false,
    };
  },
  computed: {
    ...mapState('entities/orders', ['singleOrder']),
    distributor() {
      return this.orderData.distributor || {};
    },
    isEditable() {
      return PARTIALLY_ACCEPTABLE.includes(this.orderData.status);
    },
    isSimpleOrder() {
      return (
        (this.orderData?.venue?.orderingType === 'simple' &&
          this.orderData?.distributor?.accountType !== 'premium') ||
        false
      );
    },
    checkIsMpOrToBeDetermined() {
      return this.orderData?.orderedProducts?.some(pr => pr.variableAmount || pr.marketPrice);
    },
    hasMpInOrder() {
      const products = this.orderData?.orderedProducts;
      return products?.some(pr => pr.marketPrice);
    },
    hasTbdInOrder() {
      const products = this.orderData?.orderedProducts;
      return products?.some(pr => pr.variableAmount);
    },
    canEditDeliveryOn() {
      const { editConfig } = this.orderData;

      return this.editingMode && editConfig?.deliveryDate;
    },
    canEditInvoiceNumber() {
      const { editConfig } = this.orderData;

      return this.editingMode && editConfig?.invoiceNumber;
    },
    isVenuePremium() {
      if (!isVenue(this.type)) return false;
      const {
        venue: { accountType },
      } = this.singleOrder;
      return isPremium(accountType);
    },
  },
  mounted() {
    bus.$on('confirmDelivery', this.onConfirmDeliveryClick);
    bus.$on('openDispute', this.onDisputeClick);
    bus.$on('openResolve', this.onResolveClick);
    bus.$on('abandon', this.onAbandonClick);
    bus.$on('receive', this.onReceiveClick);
    bus.$on('editMode', this.setEditMode);
    bus.$on('discardEdit', this.setEditMode);
    bus.$on('saveEdit', this.saveEdit);
  },
  methods: {
    ...mapActions('entities/orders', ['venueEditPublicOrder']),
    onAbandonClick() {
      this.$refs.abandonOrder.open();
    },
    onReceiveClick() {
      this.$refs.receiveOrder.open();
    },
    showInfoModal() {
      this.$refs.infoModal.open();
    },
    onConfirmDeliveryClick() {
      this.$refs.confirmDelivery.open();
    },
    onResolveClick() {
      this.$refs.resolveDispute.open();
    },
    onDisputeClick() {
      this.$refs.dispute.open();
    },
    onProductClick({ id }) {
      if (!this.isEditable) {
        return;
      }

      const productInd = this.orderData.orderedProducts.findIndex(p => p.id === id);

      if (productInd === -1) {
        return;
      }

      const product = this.orderData.orderedProducts[productInd];

      this.orderData.orderedProducts.splice(productInd, 1, {
        ...product,
        // isIncluded: product.isIncluded === null ? false : !product.isIncluded,
        isDeclined: product.isDeclined ? !product.isDeclined : true,
      });
    },
    setEditMode(val) {
      this.editingMode = val;
    },
    async saveEdit() {
      const config = {
        'X-Public-Token': this.token,
      };
      try {
        await this.venueEditPublicOrder({
          order: this.singleOrder,
          config,
        });
        this.editingMode = false;
        bus.$emit('editMode', false);

        const message = `You have successfully edited the order. ${this.$t(
          'global.distributor',
        )} will be notified about the change.`;
        flash.success({
          title: 'Order successfully edited.',
          ...(!this.isVenuePremium ? { message } : {}),
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .order-container__header {
  padding: 0;
}

:deep() .order-container__header .entity-info .button {
  margin-left: auto;
}

:deep() .order-container__tabs-section {
  padding: 0;
}

.order-header {
  @extend %flex-center;
}
:deep() .order-header {
  .status,
  .status-badge {
    margin-left: px-to-rem(8px);
  }
}

.mobile-actions {
  display: none;
}
@media (max-width: 768px) {
  .order-header {
    h2 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .mobile-actions {
    display: block;
    margin-left: auto;
  }
  .button {
    font-size: 18px;
  }

  :deep() .order-container__header .order-container__info-and-amount-section {
    .label {
      display: none;
    }
    .entity-info {
      margin-top: 24px;
      align-items: center;
      .venue-info__name {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }
      .venue-details {
        display: none;
      }
    }
  }
}

:deep() .public-order .public-order__wrapper .modal-wrapper.ez-info-modal .modal-backdrop {
  z-index: 100;
}
</style>
