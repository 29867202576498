<template>
  <ez-confirmation-modal ref="disputeModal" type="red">
    <template #title>Open Dispute?</template>
    <template #content>
      <p>If there’s something not right with your order, you can open a dispute.</p>

      <p>Please leave an explanation of the dispute and
        possibly add some images to back up your claim.</p>
      <ez-form
        ref="disputeForm"
        @success="disputeSuccess"
        method="patch"
        submitType="multipart"
        :action="action"
        :additional-data="imageData"
        :additional-headers="additionalHeaders"
        :form-key="formKey">
        <ez-textarea
          placeholder="Explain why are you opening a dispute."
          :required="true"
          :formKey="formKey"
          name="message"
          label="Note"
          @onChange="(val) => disputeMessage = val"
        />
        <ez-multiple-image-upload
          :formKey="formKey"
          accept="image/jpeg,image/png"
          ref="imageUpload"
          @change="imagesUploaded"/>
      </ez-form>
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button
        @click="submitDispute"
        :disabled="submitDisabled"
        type="red">Open Dispute</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import EzTextarea from '@/components/ui/Textarea';
import EzForm from '@/components/ui/Form';
import { EzMultipleImageUpload } from '@/components/ui/ImageUpload';
import Order from '@/models/Order';
import flash from '@/components/ui/FlashMessage';

/**
 * Open dispute
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzForm,
    EzTextarea,
    EzConfirmationModal,
    EzButton,
    EzMultipleImageUpload,
  },
  props: {
    venueId: {
      type: Number,
    },
    orderId: {
      type: Number,
    },
    action: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      formKey: 'dispute-form',
      imageData: {},
      disputeMessage: '',
    };
  },
  computed: {
    submitDisabled() {
      return !(this.disputeMessage.trim().length > 0);
    },
    additionalHeaders() {
      return this.token ? { 'X-Public-Token': this.token } : {};
    },
  },
  methods: {
    open() { this.$refs.disputeModal.open(); },
    close() { this.$refs.disputeModal.close(); },
    submitDispute() { this.$refs.disputeForm.onSubmit(); },
    /** @param {FormData} formData */
    imagesUploaded(formData) {
      this.imageData = formData;
    },
    async disputeSuccess({ data }) {
      await Order.update(data);
      this.$emit('success');
      this.close();
      flash.fail({
        title: 'Dispute opened.',
        message: 'You can go to order overview to see detailed info about the disputed order.',
      });
      this.$refs.disputeForm.reset();
      this.imageData = {};
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
