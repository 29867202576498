<template>
  <div class="detail-boxes">
    <slot/>
  </div>
</template>

<script>
/**
 * DetailBoxes
 * @version 1.0.0
 * @since
 */
export default {};
</script>

<style lang="scss" scoped>
.detail-boxes {
  display: flex;

  .detail-box + .detail-box::before {
    @include absolute(top 50% left 0);
    height: 32px;
    width: 1px;
    margin-top: -16px;
    background-color: #DDDFE6;
    content: '';
  }
}
</style>
