<script>
import Paper from '@/components/layout/WizardOverviewPaper';
import { wizardListenerMixin } from '@/mixins/wizard';
import CreateNewUser from '@/views/platform/venue/suppliers/onboarding/CreateNewUser';
import { mapActions, mapMutations, mapState } from 'vuex';
import { VENUE_USER_ROLE_FINANCE, VENUE_USER_ROLE_ORDERER } from '@/util/constants';
import EzCheckbox from '@/components/ui/Checkbox';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    CreateNewUser,
    EzCheckbox,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    distributor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      financeContact: {},
      orderingContact: {},
      orderingDirty: false,
      sameAsAccountFinance: false,
      sameAsAccountOrdering: false,
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'publicOnboarding',
    ]),
    additionalHeader() {
      return {
        'X-Public-Token': this.token,
      };
    },
    venue() {
      return this.publicOnboarding.venue;
    },
    action() {
      return `/public/distributors/${this.distributor.id}/venues/${this.venue.id}/users`;
    },
    actionFinance() {
      return this.hasFinance ? `${this.action}/${this.financeContact.id}` : this.action;
    },
    actionOrdering() {
      return this.hasOrdering ? `${this.action}/${this.orderingContact.id}` : this.action;
    },
    config() {
      return {
        headers: {
          'X-Public-Token': this.token,
        },
      };
    },
    hasFinance() {
      return Object.keys(this.financeContact).length > 0;
    },
    hasOrdering() {
      return Object.keys(this.orderingContact).length > 0;
    },
    hasContacts() {
      return this.hasFinance && this.hasOrdering;
    },
  },
  watch: {
    sameAsAccountFinance(val) {
      if (val) this.financeContact = this.publicOnboarding.owner;
      else this.financeContact = {};
    },
    sameAsAccountOrdering(val) {
      if (val) this.orderingContact = this.publicOnboarding.owner;
      else {
        this.orderingContact = {};
        this.$refs.orderingNewUser.$refs.userForm.clearErrors();
      }
    },
  },
  methods: {
    makeAdditionalData(role) {
      return {
        roleKey: role,
      };
    },
    async fetchContacts() {
      const { data } = await this.fetchPublicExistingOnboardingContacts({
        config: this.config,
        distributorId: this.distributor.id,
        venueId: this.venue.id,
      });
      this.financeContact = data.data.find(user => user.role.key === VENUE_USER_ROLE_FINANCE) || {};
      this.orderingContact = data.data
        .find(user => user.role.key === VENUE_USER_ROLE_ORDERER) || {};
    },
    onNextStep() {
      if (!this.sameAsAccountFinance) {
        this.$refs.financeNewUser.$refs.userForm.onSubmit();
      } else if (!this.sameAsAccountOrdering && this.orderingDirty) {
        this.$refs.orderingNewUser.$refs.userForm.onSubmit();
      } else {
        this.updateStore();
        this.$emit('stepCompleted');
      }
    },
    addedFinance({ data }) {
      this.financeContact = data.data;
      this.updateStore();
      if (!this.sameAsAccountOrdering && this.orderingDirty) {
        this.$refs.orderingNewUser.$refs.userForm.onSubmit();
        return;
      }
      this.$emit('stepCompleted');
    },
    addedOrdering({ data }) {
      this.orderingContact = data.data;
      this.updateStore();
      this.$emit('stepCompleted');
    },
    updateStore() {
      this.UPDATE_PUBLIC_ONBOARDING({
        financeUser: this.financeContact,
        orderingUser: this.orderingContact,
        sameAsAccountFinance: this.sameAsAccountFinance,
        sameAsAccountOrdering: this.sameAsAccountOrdering,
      });
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    ...mapActions('entities/venues', [
      'fetchPublicExistingOnboardingContacts',
    ]),
    ...mapMutations('entities/venues', [
      'UPDATE_PUBLIC_ONBOARDING',
    ]),
  },
  async mounted() {
    await this.fetchContacts();
    this.disableFooterActions();
    this.enableNextStep();
    this.sameAsAccountFinance = this.publicOnboarding.sameAsAccountFinance
      ? this.publicOnboarding.sameAsAccountFinance : false;
    this.sameAsAccountOrdering = this.publicOnboarding.sameAsAccountOrdering
      ? this.publicOnboarding.sameAsAccountOrdering : false;
  },
};
</script>
<template>
  <div>
    <paper>
      <template #title>Finance Contact</template>
      <template #template>
        <ez-checkbox
          form-key="sameOwner"
          :checked="sameAsAccountFinance"
          @change="() => sameAsAccountFinance = !sameAsAccountFinance"
          class="ez-checkbox"
          label="Same As Account"
          name="owner"
        />
      </template>
      <create-new-user
        :additionalHeaders="additionalHeader"
        :data="financeContact"
        :action="actionFinance"
        :disabled="sameAsAccountFinance"
        :method="hasFinance ? 'patch' : 'post'"
        :additionalData="!hasFinance ? makeAdditionalData('venue_finance') : {}"
        @complete="addedFinance"
        class="user-form"
        :hacCancel="false"
        formKey="addNewFinance"
        ref="financeNewUser" />
    </paper>
    <paper>
      <template #title>Ordering Contact</template>
      <template #template>
        <ez-checkbox
          form-key="sameOrdering"
          :checked="sameAsAccountOrdering"
          @change="() => sameAsAccountOrdering = !sameAsAccountOrdering"
          class="ez-checkbox"
          label="Same As Account"
          name="ordering"
        />
      </template>
      <create-new-user
        :additionalHeaders="additionalHeader"
        :data="orderingContact"
        :action="actionOrdering"
        :method="hasOrdering ? 'patch' : 'post'"
        :additionalData="!hasOrdering ? makeAdditionalData('venue_orderer') : {}"
        @dirty="(val) => orderingDirty = val"
        :disabled="sameAsAccountOrdering"
        @complete="addedOrdering"
        class="user-form"
        :hacCancel="false"
        formKey="addNewOrdering"
        ref="orderingNewUser" />
    </paper>
  </div>
</template>
<style lang="scss" scoped>
  .user-form {
    border-top: 0;
    padding-top: 0;
  }
  .ez-checkbox {
    :deep() .input-group {
      &__label {
        color: $color-gray-6C;
      }
    }
  }
</style>
