<template>
  <div>
    <header>
      <h2>
        <span class="check-icon"><font-awesome-icon icon="check-circle" /></span>
        Order #{{ order.orderNumber }} successfully {{ status }}
      </h2>
      <p>
        To track your orders and see complete order history, create a FREE account on
        {{ oezPlatformName }}.
      </p>
      <ez-button-group>
        <a href="mailto:hi@orderez.co" class="button button--primary">Request an Invite</a>
        <export-button
          :order="order"
          :public-order="true"
          :public-token="token"
          type="distributor"
          direction="down"
        />
      </ez-button-group>
    </header>

    <hr class="solid" />

    <ez-entity-info :imgUrl="venue.logo" imgBorderRadius="50%">
      <span>{{ venue.name }}</span>
    </ez-entity-info>
    <info-panel :data="venue" />

    <hr class="solid" />

    <h3>Order Details</h3>
    <detail-boxes>
      <detail-box label="Order Number">{{ order.orderNumber }}</detail-box>
      <detail-box label="Order Date">{{ order.orderedAt | dateTime }}</detail-box>
      <detail-box v-if="!isSimpleOrder" label="Total Amount">
        <div class="u-flex-h-center">
          <v-subtotal-info
            class="subtotal-info mr-4"
            :item-list="itemList"
            :delivery-fee="deliveryFee"
            :tax="tax"
            :show-total="false"
          />
          {{ order.amountWithTax | price(order.currency) }}
        </div>
      </detail-box>
    </detail-boxes>

    <ez-button type="link" :is-full-width="true" @click="$emit('hideOverview')">
      Go to Order Details
    </ez-button>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
import { DetailBoxes, DetailBox } from '@/components/ui/DetailBoxes';
import ExportButton from '@/views/common/orders/ExportButton.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzButton, { EzButtonGroup } from '@/components/ui/Button';
import InfoPanel from '@/components/ui/InfoPanel/InfoPanel.vue';
import { mapGetters } from 'vuex';
import { LOADING_KEY, statusLabels } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';

/**
 * OrderOverview
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    VSubtotalInfo,
    InfoPanel,
    EzButton,
    DetailBox,
    DetailBoxes,
    EzEntityInfo,
    ExportButton,
    EzButtonGroup,
    EzLoader,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    isSimpleOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    venue() {
      return this.order.venue || {};
    },
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.EXPORT_ORDER]);
    },
    status() {
      return statusLabels[this.order?.status].toLowerCase();
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.order.orderedProducts;
    },
    tax() {
      return this.order?.tax || 0;
    },
    deliveryFee() {
      return this.order?.deliveryFee || 0;
    },
    // Props for VSubtotalInfo - End
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-icon {
  @include font-size(16px);
  color: $color-primary-green;
}
.ez-info-panel {
  margin: px-to-rem(24px) 0;
}
.detail-boxes {
  margin-bottom: px-to-rem(32px);
}

.subtotal-info :deep() .v-subtotal-info__icon {
  @include font-size(12px);
}
</style>
