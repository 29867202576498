<template>
  <ez-confirmation-modal ref="scheduleModal" icon="question">
    <template #title>Schedule for Delivery?</template>
    <template #content>
      <p v-if="order.requestedDeliveryAt">
        {{ $t('global.venue') }} has requested the delivery date for this order. <br />
        You can decline it if you are not able to complete the delivery on the requested date.
      </p>
      <p v-else>
        Choose when your order will be delivered to the {{ $t('global.venue') | lowercase }}.
      </p>

      <ez-form
        :action="action"
        method="patch"
        ref="scheduleForm"
        submit-type="multipart"
        @success="scheduleFormSuccess"
        :additional-data="additionalData"
        :additional-headers="additionalHeaders"
        :form-key="formKey"
      >
        <v-date-picker
          v-model="selectedDate"
          name="date"
          :label="order.requestedDeliveryAt ? 'Requested Delivery Date' : 'Delivery Date'"
          :min="minDate"
          :form-key="formKey"
          is-full-width
          @dateChange="selectedDate = $event"
        />

        <hr />

        <ez-notes v-if="hasNotes" :order="order" />

        <ez-textarea
          class="mt-8"
          name="message"
          label="Note"
          placeholder="Leave a Note"
          :form-key="formKey"
        />
      </ez-form>
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button
        @click="scheduleForm"
        :disabled="!canSubmit"
        :is-loading="isLoading"
      >
        Confirm
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import EzForm from '@/components/ui/Form';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import { DATE_INPUT_FORMAT } from '@/util/constants';
import EzNotes from '@/views/common/orders/notes/Notes';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';

/**
 * Schedule Order
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzTextarea,
    EzNotes,
    VDatePicker,
    EzForm,
    EzConfirmationModal,
    EzButton,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'schedule-order',
      today: dayjs().format(DATE_INPUT_FORMAT),
      selectedDate: dayjs(this.order.expectedAt).format(DATE_INPUT_FORMAT),
      canSubmit: true,
      loadingKey: 'schedule-form-submit-public',
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    action() {
      return `distributor/public/orders/${this.order.id}/schedule`;
    },
    additionalHeaders() {
      return { 'X-Public-Token': this.token, loadingKey: this.loadingKey };
    },
    additionalData() {
      const formData = new FormData();
      this.excludedProductsIds.forEach(p => formData.append('declinedProducts[]', p));
      const date = dayjs(this.selectedDate).format(DATE_INPUT_FORMAT);
      formData.append('date', date);
      return formData;
    },
    hasNotes() {
      return (this.order.notes || []).some(n => !!n.message || !!n.images?.length);
    },
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
    minDate() {
      if (this.order.restrictDeliveryDates) {
        const isAfter = dayjs(this.order.expectedAt).isAfter(this.today);
        const isBefore = dayjs(this.order.expectedAt).isBefore(this.today);
        if (isAfter) {
          return dayjs(this.order.expectedAt).format('YYYY-MM-DD');
        }
        if (isBefore) {
          return this.today;
        }
      }
      return this.today;
    },
    isLoading() {
      return this.getLoading(this.loadingKey);
    },
  },
  methods: {
    open() {
      this.$refs.scheduleModal.open();
    },
    close() {
      this.$refs.scheduleModal.close();
    },
    scheduleForm() {
      this.$refs.scheduleForm.onSubmit();
    },
    scheduleFormSuccess() {
      this.$emit('success');
      this.close();

      flash.success({
        title: 'Order scheduled for delivery!',
        message: 'You can see the order overview in Order Details.',
      });
    },
  },
};
</script>
