<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import VenueInfoForm from '@/views/public/onboarding/new-outlet/VenueInfoForm';
import EzImageUpload from '@/components/ui/ImageUpload/EzImageUpload';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    VenueInfoForm,
    EzImageUpload,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    distributor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'venue-settings',
      imgModified: false,
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'publicOnboarding',
    ]),
    ...mapGetters('defaultImages', [
      'getDefaultImage',
    ]),
    additionalHeader() {
      return {
        'X-Public-Token': this.token,
      };
    },
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    isUpdateVenue() {
      return Object.keys(this.publicOnboarding.venue).length > 0;
    },
    venueId() {
      return this.publicOnboarding.venue.id;
    },
    action() {
      return this.isUpdateVenue ? `/public/distributors/${this.distributor.id}/venues/${this.venueId}`
        : `/public/distributors/${this.distributor.id}/venues`;
    },
    method() {
      return this.isUpdateVenue ? 'patch' : 'post';
    },
  },
  methods: {
    onNextStep() {
      this.$refs.form.submitForm();
    },
    onSubmitSuccess({ data }) {
      this.UPDATE_PUBLIC_ONBOARDING({
        venue: data.data,
      });
      this.$emit('stepCompleted');
    },
    isFormValid(val) {
      if (val) this.enableNextStep();
      else this.disableNextStep();
    },
    ...mapMutations('entities/venues', [
      'UPDATE_PUBLIC_ONBOARDING',
    ]),
    ...mapActions('defaultImages', ['fetchDefaultImages']),
  },
  mounted() {
    this.fetchDefaultImages();
    this.disableFooterActions();
  },
};
</script>
<template>
  <div class="new-venue-form">
    <VenueInfoForm
      :additionalHeaders="additionalHeader"
      ref="form"
      :method="method"
      :action="action"
      :data="publicOnboarding.venue"
      submitType="multipart"
      @formValid="isFormValid"
      @submitSuccess="onSubmitSuccess"
      :formKey="formKey">
      <template #prepend>
        <ez-image-upload
          class="image-uploader"
          :formKey="formKey"
          :isRound="true"
          :previewUrl="publicOnboarding.venue.logo || defaultImage"
          :columnMode="true"
          name="logo">
          <template #addImage>
            <span>Add {{ $t('global.venue') }} Logo</span>
          </template>
          <template #removeImage>
            <font-awesome-icon icon="times"/>
            <span>Remove Logo</span>
          </template>
        </ez-image-upload>
      </template>
    </VenueInfoForm>
  </div>
</template>
<style lang="scss" scoped>
  .new-venue-form {
    display: flex;
    justify-content: center;
  }
</style>
