<template>
  <div class="public-order" v-if="!loading">
    <div v-if="orderData && !orderSuccessMessage" class="public-order__wrapper">
      <!--   Info messages // Start   -->

      <ez-alert variant="disclaimer" v-if="editingMode" size="big">
        <template #icon>
          <font-awesome-icon icon="exclamation-circle" />
        </template>
        <template #title>
          <span>Edit mode active</span>
        </template>
        <p>
          Edit the existing products to this order. Once you save the changes, the
          {{ $t('global.venue') }} will be notified.
        </p>
      </ez-alert>

      <div class="mb-16" v-if="checkIsMpOrToBeDetermined && hasMpInOrder && !editingMode">
        <ez-alert variant="disclaimer" class="m-0" size="big">
          <template #icon>
            <font-awesome-icon icon="exclamation-circle" />
          </template>
          <p v-if="hasMpInOrder && hasTbdInOrder">
            This order contains products with market prices and fractional units which may affect
            the total amount (incl. subtotal and taxes) based on the actual delivered quantity.
          </p>
          <p v-if="hasMpInOrder && !hasTbdInOrder">
            Product with market price is included in this order. Market prices affect the total
            amount, subtotal and taxes.
          </p>
        </ez-alert>
      </div>

      <!--   Info messages // End   -->

      <abandon-order
        ref="abandonOrder"
        :token="token"
        :order="orderData"
        type="distributor"
        @success="$emit('refresh')"
      />

      <accept-order
        ref="orderConfirmation"
        :order="orderData"
        :token="token"
        @success="goToOverview"
      />

      <decline-order
        ref="orderDecline"
        :order="orderData"
        :token="token"
        @success="$emit('refresh')"
      />

      <schedule-order
        ref="orderSchedule"
        :order="orderData"
        :token="token"
        @success="goToOverview"
      />

      <confirm-delivery
        ref="confirmDelivery"
        :order="orderData"
        :action="`/distributor/public/orders/${orderId}/complete`"
        :token="token"
        @success="$emit('refresh')"
      />

      <ez-info-modal ref="infoModal" v-if="orderData.customerInfo" :data="orderData.customerInfo">
        <template #title>Customer Info</template>
      </ez-info-modal>

      <header class="order-header">
        <h2>Order Request</h2>
        <status-badge :status="orderData.status || 'pending'" />
        <div class="mobile-actions">
          <ez-button class="venue-details" type="link" formType="button" @click="showVenueInfo">
            <font-awesome-icon icon="info-circle" />
          </ez-button>
          <ez-button @click="downloadFile()" type="link">
            <font-awesome-icon icon="download" />
          </ez-button>
        </div>
      </header>
      <single-order
        :editing-mode="editingMode"
        :can-edit-delivery-on="canEditDeliveryOn"
        :can-edit-invoice-number="canEditInvoiceNumber"
        :order="orderData"
        @productClick="onProductClick"
        @editedProduct="() => $emit('editedProduct')"
        @editDeliveryOn="() => $emit('editDeliveryOn')"
        @editInvoiceNumber="() => $emit('editInvoiceDate')"
        :isPublicOrder="true"
        :isSimpleOrder="isSimpleOrder"
        :token="token"
        :type="type"
        :invoice-action="`/distributor/public/orders/${orderId}/invoice`"
      >
        <template #venue>
          <ez-entity-info :imgUrl="venue.logo" imgBorderRadius="50%">
            <div class="venue-info">
              <span class="venue-info__name">{{ venue.name }}</span>
              <span v-if="hasAddress" class="venue-info__address" :title="venueAddress">
                {{ venueAddress }}
              </span>
            </div>
            <div class="venue-details-wrapper">
              <div class="desktop-actions mr-12">
                <ez-button @click="downloadFile()" type="secondary">
                  <font-awesome-icon icon="download" />
                  <span>Export</span>
                </ez-button>
              </div>
              <ez-button
                class="venue-details"
                type="secondary"
                formType="button"
                @click="showVenueInfo"
              >
                <font-awesome-icon icon="info-circle" />
                <span>Customer Info</span>
              </ez-button>
            </div>
          </ez-entity-info>
        </template>
      </single-order>
    </div>
    <order-overview
      v-else
      :order="orderData"
      @hideOverview="goToOrder"
      :token="token"
      :isSimpleOrder="isSimpleOrder"
    />
  </div>
</template>

<script>
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzButton from '@/components/ui/Button';
import { SingleOrder } from '@/views/common/orders';
import { ORDER_STATUS_PENDING, PARTIALLY_ACCEPTABLE } from '@/util/constants';
import EzAlert from '@/components/ui/Alert';
import AcceptOrder from '@/views/public/actions/AcceptOrder.vue';
import DeclineOrder from '@/views/public/actions/DeclineOrder.vue';
import { EzInfoModal } from '@/components/ui/Modal';
import ScheduleOrder from '@/views/public/actions/ScheduleOrder.vue';
import StatusBadge from '@/views/common/status-badge';
import OrderOverview from '@/views/public/actions/OrderOverview.vue';
import ConfirmDelivery from '@/views/common/orders/actions/ConfirmOrder.vue';
import AbandonOrder from '@/views/public/actions/AbandonOrder';
import { mapActions, mapState } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import { isDistributor } from '@/mixins/permissions/utils';
import { isPremium } from '@/util/utils';
import bus from './bus';

export default {
  components: {
    ConfirmDelivery,
    OrderOverview,
    ScheduleOrder,
    DeclineOrder,
    AcceptOrder,
    EzAlert,
    EzButton,
    EzEntityInfo,
    SingleOrder,
    EzInfoModal,
    StatusBadge,
    AbandonOrder,
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    token: {
      type: String,
    },
    orderId: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      ORDER_STATUS_PENDING,
      confirmMessage: '',
      declineMessage: '',
      orderSuccessMessage: false,
      editingMode: false,
    };
  },
  computed: {
    ...mapState('entities/orders', ['singleOrder']),
    venue() {
      return this.orderData.venue || {};
    },
    isEditable() {
      return PARTIALLY_ACCEPTABLE.includes(this.orderData.status);
    },
    checkIsMpOrToBeDetermined() {
      return this.orderData?.orderedProducts?.some(pr => pr.variableAmount || pr.marketPrice);
    },
    hasMpInOrder() {
      const products = this.orderData?.orderedProducts;
      return products?.some(pr => pr.marketPrice);
    },
    hasTbdInOrder() {
      const products = this.orderData?.orderedProducts;
      return products?.some(pr => pr.variableAmount);
    },
    canEditDeliveryOn() {
      const { editConfig } = this.orderData;

      return this.editingMode && editConfig?.deliveryDate;
    },
    canEditInvoiceNumber() {
      const { editConfig } = this.orderData;

      return this.editingMode && editConfig?.invoiceNumber;
    },
    hasAddress() {
      return !!this.venue.shippingAddress?.formattedAddress;
    },
    venueAddress() {
      return this.venue.shippingAddress.formattedAddress;
    },
    isDistributorPremium() {
      if (!isDistributor(this.type)) return false;
      const {
        distributor: { accountType },
      } = this.singleOrder;
      return isPremium(accountType);
    },
    isSimpleOrder() {
      return (
        (this.orderData?.venue?.orderingType === 'simple' &&
          this.orderData?.distributor?.accountType !== 'premium') ||
        false
      );
    },
  },
  methods: {
    ...mapActions('entities/orders', ['distributorEditPublicOrder']),
    ...mapActions('entities/orders', ['downloadOrder']),
    downloadFile(fileType = 'pdf') {
      const { orderData } = this;

      const config = {
        ...(this.token && { 'X-Public-Token': this.token }),
      };

      this.downloadOrder({
        id: orderData.id,
        fileType,
        publicOrder: !!this.token,
        config,
        type: this.type,
        exportType: orderData.exportTypes[0],
      }).then(({ data, headers }) => {
        const blob = new Blob([data]);
        const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
        const name = Array.isArray(filename) ? decodeURI(filename[1]) : `Orders.${this.type}`;

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, name);
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');

          link.href = url;
          link.download = name;
          link.click();

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      });
    },
    showVenueInfo() {
      this.$refs.infoModal.open();
    },
    onAcceptOrderClick() {
      this.$refs.orderConfirmation.open();
    },
    closeConfirmModal() {
      this.$refs.orderConfirmation.close();
    },
    onDeclineOrderClick() {
      this.$refs.orderDecline.open();
    },
    closeDeclineModal() {
      this.$refs.orderDecline.close();
    },
    onScheduleOrderClick() {
      this.$refs.orderSchedule.open();
    },
    onConfirmDeliveryClick() {
      this.$refs.confirmDelivery.open();
    },
    onAbandonClick() {
      this.$refs.abandonOrder.open();
    },
    goToOverview() {
      this.$emit('refresh');
      bus.$emit('hideFooter');
      this.orderSuccessMessage = true;
    },
    goToOrder() {
      bus.$emit('showFooter');
      this.orderSuccessMessage = false;
    },
    onProductClick({ id }) {
      if (!this.isEditable) {
        return;
      }

      const productInd = this.orderData.orderedProducts.findIndex(p => p.id === id);

      if (productInd === -1) {
        return;
      }

      const product = this.orderData.orderedProducts[productInd];

      this.orderData.orderedProducts.splice(productInd, 1, {
        ...product,
        // isIncluded: product.isIncluded === null ? false : !product.isIncluded,
        isDeclined: product.isDeclined ? !product.isDeclined : true,
      });
    },
    setEditMode(val) {
      this.editingMode = val;
    },
    async saveEdit() {
      const config = {
        'X-Public-Token': this.token,
      };
      try {
        await this.distributorEditPublicOrder({
          order: this.singleOrder,
          config,
        });
        this.editingMode = false;
        bus.$emit('editMode', false);

        const message = `You have successfully edited the order. ${this.$t(
          'global.venue',
        )} will be notified about the change.`;
        flash.success({
          title: 'Order successfully edited.',
          ...(!this.isDistributorPremium ? { message } : {}),
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
  created() {
    window.fbq('track', 'PageView');
    bus.$on('acceptOrder', this.onAcceptOrderClick);
    bus.$on('declineOrder', this.onDeclineOrderClick);
    bus.$on('scheduleOrder', this.onScheduleOrderClick);
    bus.$on('confirmDelivery', this.onConfirmDeliveryClick);
    bus.$on('abandon', this.onAbandonClick);
    bus.$on('editMode', this.setEditMode);
    bus.$on('discardEdit', this.setEditMode);
    bus.$on('saveEdit', this.saveEdit);
  },
};
</script>

<style lang="scss" scoped>
:deep() .order-container__header {
  padding: 0;
}

:deep() .order-container__header .entity-info {
  .venue-info {
    display: flex;
    flex-direction: column;

    &__address {
      @include font-size(12px, 18px);
      color: $color-gray-6C;
      max-width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }
  }

  .button {
    margin-left: auto;
  }
}

:deep() .order-container__tabs-section {
  padding: 0;
}

.order-header {
  @extend %flex-center;
}

:deep() .order-header {
  .status,
  .status-badge {
    margin-left: px-to-rem(8px);
  }
}

.mobile-actions {
  display: none;
}
.desktop-actions {
  margin-left: auto;
}
.venue-details-wrapper {
  margin-left: auto;
  display: flex;
}
@media (max-width: 768px) {
  .order-header {
    h2 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .mobile-actions {
    display: block;
    margin-left: auto;
  }
  .desktop-actions {
    display: none;
  }
  .button {
    font-size: 18px;
  }

  :deep() .order-container__header .order-container__info-and-amount-section {
    .label {
      display: none;
    }
    .entity-info {
      margin-top: 24px;
      align-items: center;
      .venue-info {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }
      .venue-details {
        display: none;
      }
    }
  }
}

:deep() .public-order .public-order__wrapper .modal-wrapper.ez-info-modal .modal-backdrop {
  z-index: 100;
}
</style>
