<template>
  <ez-button-group>
    <buttons-actions
      @decline="$emit('declineOrder')"
      @accept="$emit('acceptOrder')"
      @schedule="$emit('scheduleOrder')"
      @complete="$emit('confirmDelivery')"
      @dispute="$emit('openDispute')"
      @abandon="$emit('abandon')"
      @receive="$emit('receive')"
      @resolve="$emit('openResolve')"
      @edit="$emit('edit')"
      @saveEdit="$emit('saveEdit')"
      @discardEdit="$emit('discardEdit')"
      :editing-mode="editingMode"
      :order="order"
      :are-all-products-declined="areAllIncludedProductsDeclined"
      :are-some-products-declined="areSomeProductsDeclined"
      :has-greater-then-two-actions="hasGreaterThenTwoActions"
      :to-disable-save-changes="saveChangesDisabled"
    />

    <template v-if="canExport">
      <export-button :order="order" :type="type" :public-token="token" />
    </template>
  </ez-button-group>
</template>

<script>
/**
 * Show action buttons based on order status.
 * @version 1.0.0
 * @since 2.0.0
 */
import { EzButtonGroup } from '@/components/ui/Button';
import ExportButton from '@/views/common/orders/ExportButton.vue';
import ButtonsActions from '@/views/common/orders/orderActions/ButtonsActions';
import {
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_SHIPPED,
} from '@/util/constants';
import { areAllProductsDeclined, areSomeProductsDeclined, isIncluded } from '@/util/utils';

export default {
  components: {
    ExportButton,
    EzButtonGroup,
    ButtonsActions,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    editingMode: {
      type: Boolean,
      required: false,
    },
    saveChangesDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    canExport() {
      return (
        [
          ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
          ORDER_STATUS_COMPLETED,
          ORDER_STATUS_ACCEPTED,
          ORDER_STATUS_PARTIALLY_ACCEPTED,
          ORDER_STATUS_SHIPPED,
        ].indexOf(this.status) !== -1
      );
    },
    hasGreaterThenTwoActions() {
      return this.order.actions.length > 2;
    },
    areAllIncludedProductsDeclined() {
      const included = this.order.orderedProducts.filter(isIncluded);
      return areAllProductsDeclined(included);
    },
    areSomeProductsDeclined() {
      return areSomeProductsDeclined(this.order.orderedProducts);
    },
  },
};
</script>
