<template>
  <div class="auth">
    <center-layout>
      <template #heading>
        <div class="auth__heading">
          <img v-if="oezPlatformName === 'Foodrazor'" src="@/assets/Foodrazor-logo.svg" alt="Foodrazor logo">
          <img v-else src="@/assets/logo-dark.svg" alt="EZ logo">
        </div>
      </template>

      <ez-form
        class="auth__form"
        :action="`/free-trial`"
        method="post"
        :formKey="formKey"
        :additionalData="{
          ...(sourceId ? { sourceId }: {}),
          ...(source ? { source } : {}),
        }"
        @success="onSuccess"
        @error="onError"
      >
        <template #header>
          <h1 v-if="success">Free Trial Email Sent</h1>
          <h1 v-else>Start a Free Trial</h1>
        </template>

        <p v-if="success">
          Finish setting up your account from a link sent to your email address.
        </p>
        <p v-else>
          Enter your information below and we’ll send you a link to set up your Free Trial account.
        </p>

        <ez-input
          class="mt-12"
          type="text"
          label="Full Name*"
          :value="fullName"
          placeholder="Enter your full name"
          name="fullName"
          :formKey="formKey"
          :disabled="success"
        />

        <ez-input
          class="mt-12"
          type="email"
          label="Email*"
          :value="email"
          placeholder="admin@example.com"
          name="email"
          :formKey="formKey"
          :disabled="success"
        />

        <ez-input
          class="mt-12"
          :formKey="formKey"
          placeholder="Phone Number"
          label="Phone Number"
          name="phoneNumber"
          :disabled="success"
        />

        <ez-input
          class="mt-12"
          type="text"
          label="Organisation Name*"
          :value="organisationName"
          placeholder="Enter your organisation name"
          name="organisationName"
          :formKey="formKey"
          :disabled="success"
        />

        <ez-dropdown
          class="mt-12"
          name="type"
          :form-key="formKey"
          :data="businessTypes"
          label="Your Type of Business*"
          is-full-width
          @change="onBusinessTypeSelectChange"
          :selected="type.id"
          :disabled="success"
        />

        <ez-button :disabled="success" form-type="submit" isFullWidth>Submit</ez-button>

      </ez-form>
    </center-layout>
  </div>
</template>

<script>
import CenterLayout from '@/components/layout/Center.vue';
import flash from '@/components/ui/FlashMessage';
import EzInput from '@/components/ui/Input';
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  components: {
    CenterLayout,
    EzForm,
    EzInput,
    EzButton,
    EzDropdown,
  },
  data() {
    return {
      formKey: 'free-trial',
      businessTypes: [
        {
          id: 'distributor',
          name: 'Supplier',
          value: 'distributor',
        },
        {
          id: 'venue',
          name: 'Outlet',
          value: 'venue',
        },
      ],
      businessType: {
        id: 'distributor',
        name: 'Supplier',
        value: 'distributor',
      },
      success: false,
    };
  },
  computed: {
    fullName() {
      return this.$route.query.fullName;
    },
    email() {
      return this.$route.query.email;
    },
    organisationName() {
      return this.$route.query.organisationName;
    },
    type() {
      if (this.$route.query.type) {
        return this.businessTypes.filter(item => item.value === this.$route.query.type.toLowerCase())[0];
      }
      return this.businessType;
    },
    source() {
      return this.$route.query.source;
    },
    sourceId() {
      return this.$route.query.sourceId;
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    onBusinessTypeSelectChange(val) {
      this.businessType = val;
    },
    onSuccess() {
      this.success = true;
    },
    onError() {
      flash.error({
        title: 'Sign up failed!',
        message: 'There was an error signing up',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color:   #4D7CFE;
$label-color:     #8790A3;
$default-color:   #252631;
$text-color:      #6C7995;

.auth {
  width: 100%;
  padding-bottom: 2rem;
  &__heading{
    @extend %flex-center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5rem 0 1rem 0;
    margin: 0 auto;
    width: 7.75rem;
    display: block;
    span {
      @include font-size(20px);
      color: $label-color;
      margin-left: 0.5rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__form {
    margin: 0 auto;
    h1 {
      @include font-size(24px);
      font-weight: 500;
      text-align: center;
      margin: 0;
    }
    p {
      @include font-size(14px);
      color: $text-color;
      text-align: center;
      line-height: 1.75;
      margin: .5rem 0 2rem 0;
    }
    button {
      margin-top: 1rem;
    }
    :deep() .input-group {
      width: 100%;
      &__input {
        height: 2.25rem;
      }
    }
  }
  :deep() .layout-center {
    overflow-y: initial;
  }
}

:deep() .input-group__input input:not(:read-only):focus {
  margin-left: 0px;
}
</style>
