<template>
  <ez-confirmation-modal ref="orderConfirmation" icon="question">
    <template #title>{{ acceptText }}?</template>
    <template #content>
      <p>
        Make sure you went through the whole order in detail and that you can deliver all products
        from the list that are included in the order.
      </p>

      <hr />

      <ez-notes v-if="hasNotes" :order="order"></ez-notes>

      <ez-textarea
        name="note"
        label="Note"
        formKey="note"
        @onChange="val => (confirmMessage = val)"
        placeholder="Add a Note. (Optional)"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="close"
        :data-cy="commonCy.SINGLE_ORDER.ACCEPT_ORDER_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="approveOrder"
        :data-cy="commonCy.SINGLE_ORDER.ACCEPT_ORDER_MODAL.BUTTON__ACCEPT"
      >
        {{ acceptText }}
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import { EzNotes } from '@/views/common/orders/notes';
import flash from '@/components/ui/FlashMessage';
import EzTextarea from '@/components/ui/Textarea';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

/**
 * AcceptOrder
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzTextarea,
    EzConfirmationModal,
    EzButton,
    EzNotes,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmMessage: '',
      commonCy,
    };
  },
  computed: {
    acceptText() {
      return this.excludedProductsIds.length ? 'Partially Accept Order' : 'Accept Order';
    },
    hasNotes() {
      return (this.order.notes || [])
        .filter(n => !!n.message || (n.images || []).length).length;
    },
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['publicOrderActions']),
    open() { this.$refs.orderConfirmation.open(); },
    close() { this.$refs.orderConfirmation.close(); },
    async approveOrder() {
      const config = {
        headers: {
          'X-Public-Token': this.token,
        },
      };
      const data = {
        message: this.confirmMessage,
        declinedProducts: this.excludedProductsIds,
      };
      await this.publicOrderActions({
        id: this.order.id,
        action: 'accept',
        type: 'distributor',
        config,
        data,
      });
      this.$emit('success');
      this.close();
      this.confirmMessage = '';

      flash.success({
        title: 'Order accepted.',
        message: 'You have successfully accepted the order.',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .notes { margin-bottom: 12px; }
</style>
