<script>
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';
import { mapActions } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzConfirmationModal,
    EzButton,
    EzTextarea,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      cancelNote: '',
    };
  },
  methods: {
    ...mapActions('entities/orders', ['publicOrderAbandonAction']),
    open() { this.$refs.abandon.open(); },
    close() { this.$refs.abandon.close(); },
    async confirmAbandonOrder() {
      const config = {
        headers: {
          'X-Public-Token': this.token,
        },
      };
      await this.publicOrderAbandonAction({
        id: this.order.id,
        type: this.type,
        config,
        data: {
          message: this.cancelNote,
        },
      });
      this.$emit('success');
      this.close();
      flash.success({
        title: 'Order successfully canceled.',
        message: 'You can see the order overview in Order Details.',
      });
    },
  },
};
</script>
<template>
  <ez-confirmation-modal ref="abandon" icon="question">
    <template #title>Cancel Order?</template>
    <template #content>
      <p>Are you sure you want to cancel this order?</p>
      <ez-textarea
        form-key="cancelOrder"
        placeholder="Leave a Note"
        name="message"
        label="Note"
        class="mt-8"
        @onChange="val => (cancelNote = val)"
      />
    </template>
    <template #footer>
      <ez-button type="link" @click="close">Discard</ez-button>
      <ez-button @click="confirmAbandonOrder">Cancel Order</ez-button>
    </template>
  </ez-confirmation-modal>
</template>
<style lang="scss" scoped></style>
